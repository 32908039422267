import store from "@/store";
import version from "../../package.json";

export function setIsMobile(value) {
  store.commit("isMobile", value);
}

export function getIsMobile() {
  return store.getters.isMobile;
}

export function setRoutes(value) {
  store.commit("setRoutes", value);
}

export function getRoutes() {
  return store.getters.routes;
}

export function setIsWait(value) {
  return store.commit("showWait", value);
}

export function getVersion() {
  return version.version;
}

export function fetchGet(url, wait) {
  return new Promise((resolve) => {
    if (wait) {
      store.commit("showWait", true);
    }

    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((data) => data.json())
      .then((response) => {
        if (wait) {
          store.commit("showWait", false);
        }
        return resolve(response);
      })
      .catch(() => {
        let response = {
          success: false,
          message: "Unable to call service",
        };
        if (wait) {
          store.commit("showWait", false);
        }
        return resolve(response);
      });
  });
}

export function getGalleryImages(container) {
  return new Promise((resolve) => {
    if (container !== null || container) {
      if (store.getters.pages.length == 0) {
        getAllPages();
        fetchGet(
          "https://pernicketyimagesonepage.azurewebsites.net/api/GetThumbnails?container=" +
            container,
          true
        ).then((response) => {
          resolve(response);
        });
      } else {
        getAllPages().then(() => {
          var page = store.getters.pages.find((x) => x.name === container);
          resolve(page);
        });
      }
    }
  });
}

export function getAllPages() {
  return new Promise((resolve) => {
    if (store.getters.pages.length == 0) {
      fetchGet(
        "https://getallthumbnails.azurewebsites.net/api/GetAllThumbnails?",
        false
      ).then((response) => {
        store.commit("setPages", response);
        resolve(response);
      });
    } else {
      resolve();
    }
  });
}

export function emailSend(emailForm) {
  return new Promise((resolve) => {
    store.commit("showWait", true);
    let url = "https://pernicketyemailsender.azurewebsites.net/send";
    fetch(url, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json;charset=utf-8",
        "Data-Type": "json",
      },
      body: JSON.stringify(emailForm),
    })
      .then((data) => data.json())
      .then((response) => {
        store.commit("showWait", false);
        response = {
          message: "",
          success: true,
        };
        return resolve(response);
      })
      .catch((response) => {
        if (response !== null) {
          let lower = response.toString().toLowerCase();
          if (typeof lower === "string" || lower instanceof String) {
            if (
              lower.includes("fail") ||
              (lower.includes("error") && !lower.includes("syntax"))
            ) {
              response = {
                message: response,
                success: false,
              };
            } else {
              response = {
                message: "",
                success: true,
              };
            }
          } else {
            response = {
              message: "Unable to send message.",
              success: false,
            };
          }
        }
        store.commit("showWait", false);
        return resolve(response);
      });
  });
}
