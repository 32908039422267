import Vue from "vue";
import VueRouter from "vue-router";

const titleSuffix = " | Pernickety Photography";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "misc" */ "@/views/Home.vue"),
    meta: { title: getTitle("Home"), auth: false, roles: null },
  },
  {
    path: "/about",
    name: "about",
    component: () => import(/* webpackChunkName: "misc" */ "@/views/About.vue"),
    meta: { title: getTitle("About"), auth: false, roles: null },
  },
  {
    path: "/gallery/:id",
    name: "gallery",
    component: () =>
      import(/* webpackChunkName: "misc" */ "@/views/Gallery.vue"),
    meta: { title: getTitle("Gallery") },
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "misc" */ "@/views/Contact.vue"),
    meta: { title: getTitle("Contact"), auth: false, roles: null },
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () =>
      import(/* webpackChunkName: "misc" */ "@/views/PrivacyPolicy.vue"),
    meta: { title: getTitle("Privacy Policy"), auth: false, roles: null },
  },
  {
    path: "*",
    name: "catchall",
    component: () =>
      import(/* webpackChunkName: "misc" */ "@/views/NotFound.vue"),
    meta: { title: "Not Found", auth: false, roles: null },
  },
];

function getTitle(prefix) {
  if (typeof prefix === "string" || prefix instanceof String) {
    return prefix + titleSuffix;
  } else {
    return "Incorrect input";
  }
}

const router = new VueRouter({
  mode: "history",
  routes,
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || "Pernickety Photography";

    if (document.title.startsWith("Gallery")) {
      var newTitle = document.title.replace("Gallery", to.params.id);
      if (newTitle.length > 0) {
        document.title =
          newTitle[0].charAt(0).toUpperCase() + newTitle.substring(1);
      }
    }
  });
});

export default router;
