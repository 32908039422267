<template>
  <div class="sidebar-main p-2">
    <h4 class="sidebar-header-title">
      <a href="/"
        ><img
          class="navbar-logo"
          src="https://jimjamphotographystorage.blob.core.windows.net/documents/jjp-brand.png"
        />PERNICKETY</a
      >
    </h4>
    <div class="px-3 py-2">
      <div class="my-1"><router-link to="/">Home</router-link></div>
      <div>
        <div @click="portfolioListToggle = !portfolioListToggle">
          <a>Portfolio</a>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="8"
            fill="currentColor"
            viewBox="-2 2 16 16"
          >
            <path
              v-if="!portfolioListToggle"
              d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
            />
            <path
              v-else
              d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
            />
          </svg>
        </div>
      </div>
      <div class="px-3 expandable">
        <transition name="linksAnimation">
          <div v-if="portfolioListToggle">
            <div class="py-1" v-for="(item, index) in routes" :key="index">
              <!-- <router-link :to="item.link">{{ item.name }}</router-link> -->
              <router-link
                :to="{ name: 'gallery', params: { id: item.route } }"
              >
                {{ item.name }}
              </router-link>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <hr />
    <div class="px-3 py-2">
      <div><router-link to="/about">About</router-link></div>
      <div><router-link to="/contact">Contact</router-link></div>
      <div><router-link to="/privacy">Privacy Policy</router-link></div>
    </div>
    <div class="footer">
      <div>
        Copyright © {{ new Date().getFullYear() }} Jasmin Moule - All Rights
        Reserved.
      </div>
      <a target="_blank" href="https://www.elliotmoule.com" class="link-elliot"
        >Website by Elliot Moule</a
      >
      <div>Version: {{ version }}</div>
    </div>
  </div>
</template>

<script>
import * as _core from "@/services/coreService";

export default {
  name: "cSideDrawer",
  data: () => ({
    drawer: false,
    portfolioListToggle: false,
    routes: [],
  }),
  computed: {
    galleryRoutes() {
      return _core.getRoutes();
    },
    version() {
      return _core.getVersion();
    },
  },
  watch: {
    galleryRoutes(newRoutes) {
      this.routes = newRoutes;
      this.portfolioListToggle = true;
    },
  },
  methods: {
    loadRoutes: function () {
      this.routes = _core.getRoutes();
      if (this.routes.length == 0) {
        this.routes = [];
        this.portfolioListToggle = false;
      } else {
        this.openMenu();
      }
    },
    openMenu: function () {
      if (this.portfolioListToggle) {
        this.portfolioListToggle = false;
      } else {
        this.portfolioListToggle = true;
      }
    },
  },
  mounted() {
    this.loadRoutes();
  },
};
</script>

<style scoped>
.sidebar-header-title a {
  padding: 15px 8px 10px 16px;
  text-decoration: none;
  font-size: 22px;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  color: Black;
  display: block;
}
.sidebar-header-title img {
  margin: 0 5px 0 0;
  width: 40px;
}
/* When you mouse over the navigation links, change their color */
.sidebar-header-title a:hover {
  color: black;
  text-decoration: none;
  display: block;
}
.navbar-logo {
  margin: 10 10 0 0;
}
.sidebar-main {
  padding: 2px;
}
.sidebar-main a {
  color: rgb(73, 69, 69);
}
.expandable {
  margin: 0 30px;
}
.linksAnimation-enter-active {
  transition: all 0.3s ease-out;
}

.linksAnimation-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.linksAnimation-enter-from,
.linksAnimation-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.linksAnimation-enter {
  transform: translateY(20px);
  opacity: 0;
}
.footer {
  height: 60px;
  width: 100%;
  position: absolute;
  bottom: 0;
  font-size: 12px;
  color: black;
}
.link-elliot {
  color: rgb(81, 81, 243) !important;
}
</style>
