<template>
  <b-navbar fixed="bottom" class="bottom-nav shadow" toggleable>
    <b-navbar-nav>
      <b-container class="bv-example-row">
        <b-row>
          <b-button-group>
            <b-col
              ><b-nav-item
                ><b-button class="nav-button"
                  ><router-link class="nav-toggle" to="/"
                    ><b-icon icon="house" font-scale="2"></b-icon
                    >Home</router-link
                  ></b-button
                ></b-nav-item
              ></b-col
            >
            <b-col
              ><b-nav-item
                ><b-button class="nav-button"
                  ><router-link class="nav-toggle" to="/about"
                    ><b-icon icon="info-circle" font-scale="2"></b-icon
                    >About</router-link
                  ></b-button
                ></b-nav-item
              ></b-col
            >
            <b-col
              ><b-nav-item
                ><b-button class="nav-button"
                  ><router-link class="nav-toggle" to="/contact"
                    ><b-icon icon="person-lines-fill" font-scale="2"></b-icon
                    >Contact</router-link
                  ></b-button
                ></b-nav-item
              ></b-col
            >
            <b-col>
              <b-nav-item>
                <b-navbar-toggle
                  target="navbar-toggle-collapse"
                  class="nav-toggle"
                >
                  <template #default="{ expanded }">
                    <b-icon
                      icon="chevron-bar-down"
                      font-scale="2"
                      v-if="expanded"
                    ></b-icon>
                    <b-icon icon="list" font-scale="2" v-else></b-icon>
                    <div class="nav-toggle-expand-text">Pages</div>
                  </template>
                </b-navbar-toggle>
              </b-nav-item>
            </b-col>
          </b-button-group>
          <b-collapse id="navbar-toggle-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item v-for="(item, index) in routes" :key="index">
                <b-button
                  variant="light"
                  size="sm"
                  :to="{
                    name: item.route !== 'privacy' ? 'gallery' : 'privacy',
                    params: { id: item.route },
                  }"
                  ><b-icon
                    icon="card-image"
                    aria-hidden="true"
                    class="mx-2"
                  ></b-icon
                  >{{ item.name }}</b-button
                >
              </b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-row>
      </b-container>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import * as _core from "@/services/coreService";

export default {
  name: "cBottomNav",
  data: () => ({
    routes: [],
  }),
  computed: {
    galleryRoutes() {
      return _core.getRoutes();
    },
  },
  watch: {
    galleryRoutes(newRoutes) {
      this.routes = newRoutes;
      this.portfolioListToggle = true;
    },
  },
  methods: {
    loadRoutes: function () {
      this.routes = _core.getRoutes();
      if (this.routes.length == 0) {
        this.routes = [];
        this.portfolioListToggle = false;
      } else {
        this.routes.unshift({ route: "privacy", name: "Privacy Policy" });
        this.openMenu();
      }
    },
    openMenu: function () {
      if (this.portfolioListToggle) {
        this.portfolioListToggle = false;
      } else {
        this.portfolioListToggle = true;
      }
    },
  },
  mounted() {
    this.loadRoutes();
  },
};
</script>

<style scoped>
.bottom-nav {
  background-color: rgb(83, 83, 83);
  border: 0;
  padding: 0;
}
.nav-button {
  background-color: transparent;
  border: 0;
}
.nav-toggle {
  border: 0;
  color: white;
}
.nav-toggle .b-icon {
  color: white;
}
.nav-toggle:hover {
  border: 0;
  background-color: transparent;
}
.nav-b-links {
  color: black;
}
.nav-toggle-expand-text {
  color: white;
  font-size: 0.85em;
}
#navbar-toggle-collapse {
  margin: 0 10px;
  max-height: 200px;
  overflow-x: auto;
}
</style>
