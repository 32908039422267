import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

//Local Storage keys
export const storageBaseKey = "jjp.vue";
export const storageUserKey = storageBaseKey + ".user";
export const storageAppIdKey = storageBaseKey + ".appid";

const state = {
  appId: null,
  showWait: false,
  isMobile: false,
  routes: [],
  pages: [],
};

const mutations = {
  showWait(state, value) {
    state.showWait = value;
  },
  isMobile(state, value) {
    state.isMobile = value;
  },
  setRoutes(state, value) {
    state.routes = value;
  },
  setPages(state, value) {
    state.pages = value;
  },
};

const getters = {
  appId: (state) => {
    if (state.appId != null) {
      return state.appId;
    } else {
      let ls = localStorage.getItem(storageAppIdKey);
      if (ls != null) {
        state.appId = ls;
        return ls;
      } else {
        const id = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
          /[xy]/g,
          function (c) {
            var r = (Math.random() * 16) | 0,
              v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
          }
        );
        localStorage.setItem(storageAppIdKey, id);
        state.appId = id;
        return id;
      }
    }
  },
  showWait: (state) => state.showWait,
  isMobile: (state) => state.isMobile,
  routes: (state) => state.routes,
  pages: (state) => state.pages,
};

export default new Vuex.Store({
  state,
  mutations,
  getters,
});
