<template>
  <div id="app-all">
    <div id="app" v-if="!isMobileDevice()">
      <div
        :class="[$store.getters.showWait ? 'blocker' : 'blocker-hidden']"
        v-if="$store.getters.showWait"
      ></div>
      <transition name="fade" class="spinner-div">
        <div v-if="$store.getters.showWait" class="wait-indicator">
          <div class="d-flex justify-content-center mb-3">
            <div id="sprite-container">
              <div id="sprite-image"></div>
            </div>
          </div>
          <br /><strong class="loading-text">Retrieving Images!</strong>
        </div>
      </transition>
      <cSideDrawer class="sidebar-nav" v-if="showSideDrawer" />
      <div class="main">
        <router-view />
      </div>
    </div>
    <div id="app-m" v-else>
      <div class="blocker" v-if="$store.getters.showWait"></div>
      <transition name="fade" class="spinner-div">
        <div v-if="$store.getters.showWait" class="m-wait-indicator">
          <div class="d-flex justify-content-center mb-3">
            <div id="sprite-container">
              <div id="sprite-image"></div>
            </div>
          </div>
          <br /><strong class="m-loading-text">Retrieving Images...</strong>
        </div>
      </transition>
      <cBottomNav class="bottombar-nav" />
      <div class="m-webapp">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import cSideDrawer from "@/components/Common/cSideDrawer";
import cBottomNav from "@/components/Common/cBottomNav";
import * as _core from "@/services/coreService";

export default {
  components: {
    cSideDrawer,
    cBottomNav,
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
      showSideDrawer: false,
    };
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    isMobileDevice() {
      var isMobile =
        typeof window.orientation !== "undefined" ||
        navigator.userAgent.indexOf("IEMobile") !== -1 ||
        this.window.width < 900;
      _core.setIsMobile(isMobile);
      return isMobile;
    },
    getAllRoutes() {
      var routes = [
        { route: "animals", name: "Animals" },
        { route: "landscape", name: "Landscapes" },
        { route: "portrait", name: "Portraits" },
        { route: "food-drink", name: "Food + Drink" },
      ];
      _core.setRoutes(routes);
      this.showSideDrawer = true;
    },
  },
  created() {
    _core.setIsWait(false);
    this.getAllRoutes();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style>
#app-all {
  height: 100vh;
}
#app-m {
  position: inherit;
  height: 100vh;
}
.sidebar-nav {
  height: 100%; /* Full-height: remove this if you want "auto" height */
  width: 340px; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: var(--white);
  overflow-x: hidden; /* Disable horizontal scroll */
}
/* Style page content */
.main {
  margin-left: 340px; /* Same as the width of the sidebar */
  height: 100vh;
  width: auto;
  position: relative;
  background-color: gray;
  overflow: auto;
  z-index: 1;
}
.m-webapp {
  margin-bottom: 80px; /* Same as height as the bottom navbar */
  height: calc(100vh - 80px);
  width: 100vw;
  position: relative;
  background-color: gray;
  overflow: auto;
  z-index: 1;
}
.bottombar-nav {
  overflow: hidden;
}
.loading-text {
  color: rgb(0, 0, 0);
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}
.m-loading-text {
  color: rgb(0, 0, 0);
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}
.spinner-div {
  position: relative;
}
.wait-indicator {
  position: absolute;
  z-index: 100;
  margin: 0;
  position: absolute;
  top: 90%;
  left: 170px;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.m-wait-indicator {
  position: absolute;
  z-index: 100;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.blocker {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.308);
  width: 100%;
  height: 100%;
  z-index: 2; /* Stay on top */
}
.blocker-hidden {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.308);
  width: 100%;
  height: 100%;
  z-index: -1;
}
#sprite-image {
  height: 57px;
  width: 64px;
  background: url("https://jimjamphotographystorage.blob.core.windows.net/documents/camera-3.png")
    0px 0px;
  animation: play 0.8s steps(19) infinite;
}
@keyframes play {
  100% {
    background-position: -2432px;
  }
}
</style>
